@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Medium.otf");
  font-weight: 400; }

@font-face {
  font-family: 'stag-sans';
	src: url("./css/fonts/Stag-Sans-Medium-Italic.otf");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Book.otf");
  font-weight: 300; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Book-Italic.otf");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Light.otf");
  font-weight: 200; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Light-Italic.otf");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Thin.otf");
  font-weight: 100; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Thin-Italic.otf");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Semibold.otf");
  font-weight: 500; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Semibold-Italic.otf");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Bold.otf");
  font-weight: bold;
  font-weight: 600; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Bold-Italic.otf");
  font-weight: bold;
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Black.otf");
  font-weight: 700; }

@font-face {
  font-family: 'stag-sans';
  src: url("./css/fonts/Stag-Sans-Black-Italic.otf");
  font-weight: 700;
  font-style: italic; }